var wipwrp = window.wipwrp || {};

wipwrp.switcherTipoContratto = (function($) {

    var self = {};

    self.init = function () {
        var $input = $('.collettiva__input input');

        $('.collettiva, .contratto-switcher-next').hide();

        if ($('[name=TipoContratto]:checked').val()) {
            $('.contratto-switcher-next').show();
        }

        $('#split-panel-tipocontratto').on('change','[data-trigger="tipo-contratto"]',function(e){
            var tipoContratto = $(this).data('slug');
            if( tipoContratto === 'rappresentata') {
                // collettiva
                $('.collettiva').show();
                $('.contratto-switcher-next').hide();
            } else {
                $('.collettiva').hide();
                $input.val('');
                $('.contratto-switcher-next').show();
            }
            $('[data-tipocontratto]').removeClass('active');
            $('[data-tipocontratto="+tipoContratto+"]').addClass('active');
        });

        $('.collettiva').on('click','.collettiva__input__trigger', function () {
            var value = $input.val();
            var validValues = $input.data('validcodes').split(',');
            var companies = $input.data('companies').split(',');
            var index = $.inArray( value , validValues );

            $('[class*="collettiva__check--"]').hide();

            if ( index !== -1) {
                $('.collettiva__check--ok').show();
                $('.collettiva__check > div').html(companies[index]);
                $('.contratto-switcher-next').show();
            } else {
                $('.collettiva__check--ko').show();
                $('.contratto-switcher-next').hide();
            }
        });
    };

    return self;
})(jQuery);